import React from "react"
import Button from "../Button"
import "./style.scss"

const ComponentSectionsLocations = (props) => {
    const { locations } = props.contents

    let googe_map_directions_url = "https://www.google.com/maps/dir//"

    return (
        <div className="page-section content-wrap simple-grid-2 component-locations">
            {locations.map((loc) => {
                const { Location_Phone_Number, Address, Google_Maps_Embed_Code } = loc
                return (
                    <div key={Address} className="location-wrapper flex-cc">
                        <h3>{Address}</h3>

                        <div className="map-wrapper">
                            <div dangerouslySetInnerHTML={{ __html: Google_Maps_Embed_Code }} />
                        </div>

                        <div className="button-wrapper">
                            <Button newTab={true} href={`${googe_map_directions_url}${Address}`}>
                                Directions
                            </Button>
                        </div>
                    </div>
                )
            })}
        </div>
    )
}

export default ComponentSectionsLocations
